interface ExportAssetsList {
    content: string;
    fileName: string;
    type: string;
}

/**
 * Save a base64 encoded string as file
 * @param fileName - The name of the file
 * @param data - The base64 encoded string
 * @param fileType - The type of the file
 */
export function saveBase64asFile(
    fileName: string,
    data: string,
    fileType: string,
): void {
    // Convert content string to binary data
    const binaryData = window.atob(data);
    // Create a Blob object representing the data
    const blob = new Blob(
        [new Uint8Array([...binaryData].map((char) => char.charCodeAt(0)))],
        { type: fileType },
    );
    // Create a download link and click it, clean up after
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export function saveExcelExportAsFile({
    fileName,
    type,
    content,
}: ExportAssetsList): void {
    const errors = [];
    if (!fileName) errors.push('No filename provided');
    if (!content) errors.push('No content found');
    if (type !== 'application/vnd.ms-excel') errors.push('Wrong filetype');
    if (errors.length > 0) {
        // console.error({ errors });
        return;
    }
    saveBase64asFile(fileName, content, type);
}

export function saveDocExportAsFile({
    fileName,
    type,
    content,
}: ExportAssetsList): void {
    const errors = [];
    if (!fileName) errors.push('No filename provided');
    if (!content) errors.push('No content found');
    if (
        type !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
        errors.push('Wrong filetype');
    if (errors.length > 0) {
        throw new Error(errors.join('. '));
    }
    saveBase64asFile(fileName, content, type);
}

export function saveFileAsDoc({
    fileName,
    content,
}: Omit<ExportAssetsList, 'type'>): void {
    const errors = [];
    if (!fileName) errors.push('No filename provided');
    if (!content) errors.push('No content found');
    if (errors.length > 0) {
        throw new Error(errors.join('. '));
    }

    const docExt =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    saveBase64asFile(fileName, content, docExt);
}

export function saveStringAsJsonFile(
    name: string,
    data: string,
    type: string,
): void {
    const blob = new Blob([data], {
        type,
    });
    const objectURL = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.href = objectURL;
    anchor.download = name;
    anchor.click();

    URL.revokeObjectURL(objectURL);
}
