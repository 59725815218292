import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { clsx } from 'clsx';
import { Button } from '../Button';
import type { ButtonProps } from '../Button';

export interface BlankSlateProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * Constrain the maximum width of this component
     */
    narrow?: boolean;

    /**
     * Increase the padding of this component
     */
    spacious?: boolean;
}

/**
 * Blankslate is used as placeholder to tell users why content is missing.
 * It can be used to guide users to take action.
 */
function BlankSlate({
    children,
    narrow,
    spacious,
    className,
    ...rest
}: PropsWithChildren<BlankSlateProps>): ReactNode {
    const classes = clsx(
        'ics-blank-slate',
        {
            'ics-blank-slate--narrow': narrow,
            'ics-blank-slate--spacious': spacious,
        },
        className,
    );

    return (
        <div className={classes} {...rest}>
            {children}
        </div>
    );
}

export type VisualProps = HTMLAttributes<HTMLSpanElement>;

function Visual({
    children,
    className,
    ...rest
}: PropsWithChildren<VisualProps>): ReactNode {
    const classes = clsx('ics-blank-slate__visual', className);
    return (
        <span className={classes} {...rest}>
            {children}
        </span>
    );
}

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

function Heading({
    as = 'h2',
    children,
    className,
    ...rest
}: HeadingProps): ReactNode {
    const Element = as;
    const classes = clsx('ics-blank-slate__heading', className);
    return (
        <Element className={classes} {...rest}>
            {children}
        </Element>
    );
}

export type DescriptionProps = HTMLAttributes<HTMLParagraphElement>;

function Description({
    children,
    className,
    ...rest
}: PropsWithChildren<DescriptionProps>): ReactNode {
    const classes = clsx('ics-blank-slate__description', className);
    return (
        <p className={classes} {...rest}>
            {children}
        </p>
    );
}

export interface PrimaryActionProps
    extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
    buttonVariant?: ButtonProps['variant'];
    onClick?: ButtonProps['onClick'];
    icon?: ButtonProps['icon'];
    rightIcon?: ButtonProps['rightIcon'];
}

function PrimaryAction({
    children,
    className,
    buttonVariant = 'primary',
    onClick,
    icon,
    rightIcon,
    ...rest
}: PrimaryActionProps): ReactNode {
    const classes = clsx('ics-blank-slate__action', className);
    return (
        <div className={classes} {...rest}>
            <Button
                variant={buttonVariant}
                label={children}
                onClick={onClick}
                icon={icon}
                rightIcon={rightIcon}
            />
        </div>
    );
}

export default Object.assign(BlankSlate, {
    Visual,
    Heading,
    Description,
    PrimaryAction,
});
