import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    Card,
    Flex,
    GridTemplate,
    LoadingBlur,
} from '@ics-portal/react';
import type { ReactNode } from 'react';
import { GET_PROJECT } from '~/api/queries/projects';
import { GET_MINIMAL_PROJECT_SURVEY } from '~/api/queries/survey';
import ErrorMessage from '~/components/ErrorMessage';
import VisuallyHidden from '~/components/VisuallyHidden';
import RoleBasedViewAccess from '~/components/_atoms/RoleBasedViewAccess';
import ContentController from '~/components/_organisms/Headers/ContentController';
import { InfoCardTemplate } from '~/components/_templates/InfoCardTemplate';
import type { Project } from '~/gql/graphql';
import TitleWithHelpLink from '~/components/_molecules/TitleWithHelpLink';
import { ProjectContext } from '~/pages/projects/project/_utils/contexts/ProjectContext';
import { ProjectMembersProvider } from '~/pages/projects/project/users/_utils/contexts/ManageProjectContext';
import ProjectUsers from '../users/_utils/components/ProjectUsers';
import useUserProjectRole from '../_utils/hooks/useUserProjectRole';
import Actions from './_utils/components/Actions';
import AssetsCard from './_utils/components/cards/AssetsCard';
import ComponentsCard from './_utils/components/cards/ComponentsCard';
import ProjectSurveyCard from './_utils/components/cards/ProjectSurveyCard';
import SecurityNotificationsCard from './_utils/components/cards/SecurityNotificationsCard';
import SecurityZonesCard from './_utils/components/cards/SecurityZonesCard';
import TasksCard from './_utils/components/cards/TasksCard';
import ProjectDetails from './_utils/components/ProjectDetails';

export default function Summary(): ReactNode {
    const { pid } = useParams<{ pid: string }>() as { pid: string };
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(GET_PROJECT, {
        variables: { pid },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (!data.projects?.edges[0]?.node) {
                navigate('/page-not-found');
            }
        },
    });
    const loggedInProjectUserRole = useUserProjectRole({ pid });
    const { data: psData, loading: psLoading } = useQuery(
        GET_MINIMAL_PROJECT_SURVEY,
        {
            fetchPolicy: 'cache-and-network',
            variables: { id: pid },
        },
    );

    if (error) {
        return <ErrorMessage error={error} />;
    }

    const project = data?.projects?.edges[0]?.node;

    if (!project) {
        return (
            <LoadingBlur loading>
                <p>Loading…</p>
            </LoadingBlur>
        );
    }

    return (
        <ProjectContext.Provider value={{ project: project as Project }}>
            <LoadingBlur loading={loading || psLoading}>
                <InfoCardTemplate>
                    <ContentController
                        title={
                            <TitleWithHelpLink
                                label={project.name}
                                link="https://wiki.siemens-energy.com/x/Coj3FQ"
                            />
                        }
                    >
                        <Flex gap="md" jc="flex-start">
                            <Actions
                                loggedInProjectUserRole={
                                    loggedInProjectUserRole
                                }
                            />
                        </Flex>
                    </ContentController>
                    <div className="p-content content-area">
                        <GridTemplate
                            col="minmax(0, 3fr) minmax(0, 1fr)"
                            gap="md"
                        >
                            <Flex
                                direction="column"
                                gap="md"
                                height="fit-content"
                            >
                                <TasksCard pid={pid} />
                                <ProjectSurveyCard data={psData} pid={pid} />
                                <SecurityNotificationsCard pid={pid} />
                                <SecurityZonesCard pid={pid} />
                                <AssetsCard pid={pid} />
                                <ComponentsCard pid={pid} />
                            </Flex>
                            <aside>
                                <Flex direction="column" gap="md">
                                    <Card
                                        backgroundColor="lightGrey"
                                        padding="md"
                                    >
                                        <VisuallyHidden>
                                            <h2>Project details</h2>
                                        </VisuallyHidden>
                                        <ProjectDetails />
                                    </Card>
                                    <Card
                                        backgroundColor="lightGrey"
                                        padding="md"
                                    >
                                        <h2>Members</h2>
                                        <ProjectMembersProvider>
                                            <ProjectUsers />
                                        </ProjectMembersProvider>
                                        <RoleBasedViewAccess
                                            allowedRoles={['project owner']}
                                            userRoles={[
                                                loggedInProjectUserRole,
                                            ]}
                                        >
                                            <Button
                                                onClick={() => {
                                                    navigate('members');
                                                }}
                                                label="Manage users"
                                            />
                                        </RoleBasedViewAccess>
                                    </Card>
                                </Flex>
                            </aside>
                        </GridTemplate>
                    </div>
                </InfoCardTemplate>
            </LoadingBlur>
        </ProjectContext.Provider>
    );
}
