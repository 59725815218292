import { createBrowserRouter, Navigate } from 'react-router-dom';
import { lazy } from 'react';
import * as Sentry from '@sentry/react';
import ErrorPage from './pages/ErrorPage';
import AdminDashboardPage from './pages/admin/AdminDashboardPage';
import Summary from './pages/projects/project/summary/Summary';
import Root from './Root';
import { loader as adminLoader } from './pages/admin';
import MapPage from './pages/map/MapPage';

const DashboardPage = lazy(() => import('./pages/dashboard/DashboardPage'));
const ProjectsPage = lazy(() => import('./pages/projects/Overview'));
const ProjectPage = lazy(() => import('./pages/projects/project'));
const ProjectAssetsPage = lazy(
    () => import('./pages/projects/project/assets/ProjectAssetsPage'),
);
const ProjectTasksPage = lazy(
    () => import('./pages/projects/project/tasks/ProjectTasksPage'),
);

const ProjectSecurityNotificationsPage = lazy(
    () =>
        import(
            './pages/projects/project/security-notifications/notifications/ProjectSecurityNotificationsPage'
        ),
);
const ProjectVulnerabilitiesPage = lazy(
    () =>
        import(
            './pages/projects/project/security-notifications/vulnerabilities/ProjectVulnerabilitiesPage'
        ),
);
const MitigationPage = lazy(
    () => import('./pages/projects/project/mitigation/MitigationPage'),
);
const MeasuresPage = lazy(() => import('./pages/projects/project/measures'));
const ProjectSecurityZonesPage = lazy(
    () => import('./pages/projects/project/securityZones/ProjectSecurityZones'),
);
const ProjectStandardProfilePage = lazy(
    () =>
        import(
            './pages/projects/project/securityZones/standardProfile/SecZonesStandardProfile'
        ),
);
const ProjectAssetsInZonePage = lazy(
    () => import('./pages/projects/project/securityZones/assets'),
);
const PSSProjectClassificationPage = lazy(
    () => import('./pages/projects/project/pss/PSSProjectClassificationPage'),
);
const ThreatAndRiskAnalysisPage = lazy(
    () =>
        import(
            './pages/projects/project/threat-and-risk-analysis/ThreatAndRiskAnalysisPage'
        ),
);
const ThreatAndRiskAnalysisListPage = lazy(
    () =>
        import(
            './pages/projects/project/threat-and-risk-analysis-list/threat-and-risk-analysis-list-page'
        ),
);
const ProjectComponentsPage = lazy(
    () => import('./pages/projects/project/components/ProjectComponentsPage'),
);
const ManageProjectUsersPage = lazy(
    () => import('./pages/projects/project/users/ManageProjectUsersPage'),
);

const StandardsListPage = lazy(() => import('./pages/standards/StandardsList'));
const StandardDetailsPage = lazy(
    () => import('./pages/standards/StandardDetails'),
);
const RequirementListPage = lazy(
    () => import('./pages/standards/requirements'),
);

const GlobalComponentsPage = lazy(
    () => import('./pages/components/GlobalComponentsPage'),
);
const GlobalSecurityNotificationsPage = lazy(
    () =>
        import(
            './pages/security-notifications/GlobalSecurityNotificationsPage'
        ),
);
const GlobalTasksPage = lazy(() => import('./pages/tasks/GlobalTasksPage'));

const AdminPage = lazy(() => import('./pages/admin/AdminSectionController'));
const AdminUsersPage = lazy(() => import('./pages/admin/Users'));
const AdminStandardsPage = lazy(() => import('./pages/admin/Standards'));
const AdminRequirementsPage = lazy(() => import('./pages/admin/Requirements'));
const AdminMappingsPage = lazy(() => import('./pages/admin/Mappings'));
const AdminPSSIndex = lazy(() => import('./pages/admin/PSS'));
const SupplierAssessments = lazy(
    () => import('./pages/admin/SupplierAssessments'),
);

const ProfilePage = lazy(() => import('./pages/me'));

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const Routes = [
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <DashboardPage />,
            },
            {
                path: '/map',
                element: <MapPage />,
            },
            {
                path: '/projects',
                element: <ProjectsPage />,
            },
            {
                path: '/tasks',
                element: <GlobalTasksPage />,
            },
            {
                path: '/projects/:pid',
                element: <ProjectPage />,
                children: [
                    {
                        index: true,
                        element: <Summary />,
                        exact: true,
                        strict: true,
                    },
                    {
                        path: 'tasks',
                        element: <ProjectTasksPage />,
                    },
                    {
                        path: 'members',
                        element: <ManageProjectUsersPage />,
                    },
                    {
                        path: 'pss',
                        element: <PSSProjectClassificationPage />,
                    },
                    {
                        path: 'tra',
                        element: <ThreatAndRiskAnalysisListPage />,
                    },
                    {
                        path: 'tra/:id',
                        element: <ThreatAndRiskAnalysisPage />,
                    },
                    {
                        path: 'vulnerability',
                        element: (
                            <Navigate replace to="security-notifications" />
                        ),
                    },
                    {
                        path: 'vulnerability/security-notifications',
                        index: true,
                        element: <ProjectSecurityNotificationsPage />,
                    },
                    {
                        path: 'vulnerability/security-notifications/vulnerabilities',
                        element: <ProjectVulnerabilitiesPage />,
                    },
                    {
                        path: 'vulnerability/measures',
                        element: <MeasuresPage />,
                    },
                    {
                        path: 'mitigation',
                        element: <MitigationPage />,
                    },
                    {
                        path: 'mitigation/edit/:mid',
                        element: <MitigationPage />,
                    },
                    {
                        path: 'security-zones/*',
                        element: <ProjectSecurityZonesPage />,
                        children: [
                            {
                                path: ':nodeId',
                                element: <ProjectSecurityZonesPage />,
                            },
                            {
                                path: ':nodeId/add-zone',
                                element: <ProjectSecurityZonesPage />,
                            },
                            {
                                path: ':nodeId/edit-zone',
                                element: <ProjectSecurityZonesPage />,
                            },
                        ],
                    },
                    {
                        path: 'security-zones/:nodeId/zone-assets',
                        element: <ProjectAssetsInZonePage />,
                    },
                    {
                        path: 'security-zones/:nodeId/standard-profile/:profileId',
                        element: <ProjectStandardProfilePage />,
                    },
                    {
                        path: 'assets',
                        element: <ProjectAssetsPage />,
                    },
                    {
                        path: 'components',
                        element: <ProjectComponentsPage />,
                    },
                ],
            },

            {
                path: 'standards',
                element: <StandardsListPage />,
            },
            {
                path: 'standards/:sid',
                element: <StandardDetailsPage />,
            },
            {
                path: 'standards/:sid/requirements',
                element: <RequirementListPage />,
            },
            {
                path: 'component-library',
                element: <GlobalComponentsPage />,
            },
            {
                path: 'security-notifications',
                element: <GlobalSecurityNotificationsPage />,
            },
            {
                path: 'admin',
                element: <AdminPage />,
                loader: adminLoader,
                children: [
                    {
                        index: true,
                        element: <AdminDashboardPage />,
                    },
                    {
                        path: 'users',
                        element: <AdminUsersPage />,
                    },
                    {
                        path: 'standards',
                        element: <AdminStandardsPage />,
                    },
                    {
                        path: 'requirements',
                        element: <AdminRequirementsPage />,
                    },
                    {
                        path: 'mappings',
                        element: <AdminMappingsPage />,
                    },
                    {
                        path: 'pss',
                        element: <AdminPSSIndex />,
                    },
                    {
                        path: 'supplier-assessments',
                        element: <SupplierAssessments />,
                    },
                ],
            },
            {
                path: '/me',
                element: <ProfilePage />,
            },
        ],
    },
];

const router = sentryCreateBrowserRouter(Routes, {
    basename: import.meta.env.BASE_URL,
});

export default router;
