import { graphql } from '~/gql';

export const DELETE_ASSETS = graphql(`
    mutation DeleteAssets($assetIDs: [ID!]!) {
        deleteAsset(input: { IDs: $assetIDs }) {
            id
        }
    }
`);

export const MOVE_ASSETS_TO_SECURITY_ZONE = graphql(`
    mutation MoveAssetsToSecurityZone($pzid: ID!, $assetIDs: [ID!]!) {
        updateAssetsProjectZone(
            input: { IDs: $assetIDs, projectZoneID: $pzid }
        ) {
            id
        }
    }
`);

export const UPDATE_ASSET_ITEM_LINKED_COMPONENT = graphql(`
    mutation UpdateAssetItemLinkedComponent($ids: [ID!]!, $componentID: ID) {
        updateAssetItem(input: { ids: $ids, componentID: $componentID }) {
            id
        }
    }
`);

export const UPLOAD_ASSET_LIST = graphql(`
    mutation UploadAssetList($file: Upload!, $pid: ID!) {
        importAssetsList(id: $pid, file: $file) {
            total
            successful
            unsuccessful
            assetsUpdated
            assetsCreated
            assetItemsUpdated
            assetItemsCreated
            projectZonesUpdated
            failedRows
            errors
        }
    }
`);

export const CREATE_ASSET = graphql(`
    mutation CreateAsset($input: [CreateAssetRawInput!]!) {
        createAsset(input: $input) {
            ...AssetFragment
        }
    }
`);

export const UPDATE_ASSETS = graphql(`
    mutation UpdateAssets($input: UpdateAssetBulkInput!) {
        updateAssets(input: $input) {
            ...AssetFragment
        }
    }
`);

export const CREATE_ASSET_ITEMS = graphql(`
    mutation CreateAssetItem($input: [CreateAssetItemRawInput!]!) {
        createAssetItem(input: $input) {
            ...AssetItemFragment
            asset {
                ...AssetFragment
            }
        }
    }
`);

export const UPDATE_ASSET_ITEMS = graphql(`
    mutation UpdateAssetItem($input: UpdateAssetItemBulkInput!) {
        updateAssetItem(input: $input) {
            ...AssetItemFragment
        }
    }
`);

export const DELETE_ASSET_ITEMS = graphql(`
    mutation DeleteAssetItem($input: BulkIDInput!) {
        deleteAssetItem(input: $input) {
            ...AssetItemFragment
        }
    }
`);
