import type { IconProps, IconTypes } from '@ics-portal/react';
import { Icon } from '@ics-portal/react';

const iconComponentGenerator = (iconName: IconTypes) =>
    function IconComponent(props: Omit<IconProps, 'name'>) {
        return <Icon name={iconName} {...props} />;
    };

export const enum IconEntityMap {
    Projects = 'dashboard',
    SecurityNotification = 'gpp_maybe',
    MyTask = 'checkbook',
    ComponentLibrary = 'shapes',
    StandardsLibrary = 'library_books',
    Administration = 'admin_panel_settings',
    ProjectSummary = 'dashboard',
    ProjectTasks = 'task_alt',
    ProjectPSSPClassification = 'check',
    ProjectVulnerabilityManagement = 'healing',
    ProjectSecurityZones = 'network_locked',
    ProjectComponentsLibrary = 'shapes',
    ProjectAssetsAndItems = 'stack',
    ProjectItems = 'stack',
    AdminUsers = 'group',
    AdminStandards = 'library_books',
    AdminRequirements = 'library_books',
    AdminMappings = 'beenhere',
    AdminPSSProjectClassificationTemplates = 'book',
    ProjectTypeCustomer = 'handshake',
    ProjectTypeProductDevelopment = 'book',
    ProjectTypeStandardSolution = 'description',
    ProjectTypeService = 'center_focus_strong',
    ProjectTypeFactoryLifecycle = 'factory',
    ProjectTypeDefault = 'square',
    FilterList = 'filter_list',
    Reorder = 'reorder',
    ExpandCircleDown = 'expand_circle_down',
    FilterListOff = 'filter_list_off',
    SortUp = 'arrow_upward',
    SortDown = 'arrow_downward',
    UnSorted = 'sort',
    SendFeedback = 'mail',
    Cancel = 'cancel',
    Download = 'cloud_download',
    Upload = 'cloud_upload',
    MoreOptions = 'more_vert',
    Add = 'add',
    Forward = 'arrow_forward',
    Back = 'arrow_back',
    Edit = 'edit',
    Delete = 'delete',
    Save = 'save',
    Print = 'print',
    Share = 'share',
    Refresh = 'refresh',
    Search = 'search',
    Settings = 'settings',
    Help = 'help',
    Home = 'home',
    Favorite = 'favorite',
    Close = 'close',
    Menu = 'menu',
    Notification = 'notifications',
    Person = 'person',
    Lock = 'lock',
    Unlock = 'lock_open',
    Warning = 'warning',
    Error = 'error',
    Success = 'check_circle',
    Info = 'info',
    NorthEast = 'north_east',
    ChevronLeft = 'chevron_left',
    ChevronRight = 'chevron_right',
    ChevronUp = 'keyboard_arrow_up',
    ChevronDown = 'keyboard_arrow_down',
    ChevronStart = 'first_page',
    CompactView = 'list',
    RoomyView = 'lists',
    CopyToClipboard = 'content_copy',
    DeleteForever = 'delete_forever',
    Calendar = 'calendar_month',
    Summarize = 'summarize',
    Ballot = 'ballot',
    Comment = 'comment',
    VerifiedUser = 'verified_user',
    ThreatAndRiskAnalysis = 'health_and_safety',
}

// Global level icons
export const ProjectsIcon = iconComponentGenerator(IconEntityMap.Projects);
export const SecurityNotificationIcon = iconComponentGenerator(
    IconEntityMap.SecurityNotification,
);
export const MyTaskIcon = iconComponentGenerator(IconEntityMap.MyTask);
export const ComponentLibraryIcon = iconComponentGenerator(
    IconEntityMap.ComponentLibrary,
);
export const StandardsLibraryIcon = iconComponentGenerator(
    IconEntityMap.StandardsLibrary,
);
export const AdministrationIcon = iconComponentGenerator(
    IconEntityMap.Administration,
);

// Project area icons
export const ProjectSummaryIcon = iconComponentGenerator(
    IconEntityMap.ProjectSummary,
);
export const ProjectTasksIcon = iconComponentGenerator(
    IconEntityMap.ProjectTasks,
);
export const ProjectPSSPClassificationIcon = iconComponentGenerator(
    IconEntityMap.ProjectPSSPClassification,
);
export const ProjectTRAIcon = iconComponentGenerator(
    IconEntityMap.ThreatAndRiskAnalysis,
);
export const ProjectVulnerabilityManagementIcon = iconComponentGenerator(
    IconEntityMap.ProjectVulnerabilityManagement,
);
export const ProjectSecurityZonesIcon = iconComponentGenerator(
    IconEntityMap.ProjectSecurityZones,
);
export const ProjectAssetsAndItemsIcon = iconComponentGenerator(
    IconEntityMap.ProjectAssetsAndItems,
);
export const ProjectItemsIcon = iconComponentGenerator(
    IconEntityMap.ProjectItems,
);

// Admin area icons
export const AdminUsersIcons = iconComponentGenerator(IconEntityMap.AdminUsers);
export const AdminStandardsIcons = iconComponentGenerator(
    IconEntityMap.AdminStandards,
);
export const AdminRequirementsIcons = iconComponentGenerator(
    IconEntityMap.AdminRequirements,
);
export const AdminMappingsIcons = iconComponentGenerator(
    IconEntityMap.AdminMappings,
);
export const AdminPSSProjectClassificationTemplatesIcons =
    iconComponentGenerator(
        IconEntityMap.AdminPSSProjectClassificationTemplates,
    );

// Project Types
export const ProjectTypeCustomerIcon = iconComponentGenerator(
    IconEntityMap.ProjectTypeCustomer,
);
export const ProjectTypeProductDevelopmentIcon = iconComponentGenerator(
    IconEntityMap.ProjectTypeProductDevelopment,
);
export const ProjectTypeStandardSolutionIcon = iconComponentGenerator(
    IconEntityMap.ProjectTypeStandardSolution,
);
export const ProjectTypeServiceIcon = iconComponentGenerator(
    IconEntityMap.ProjectTypeService,
);
export const ProjectTypeFactoryLifecycleIcon = iconComponentGenerator(
    IconEntityMap.ProjectTypeFactoryLifecycle,
);
export const ProjectTypeDefaultIcon = iconComponentGenerator(
    IconEntityMap.ProjectTypeDefault,
);
export const ProjectComponentsIcon = ComponentLibraryIcon;

// Grid icons
export const FilterListIcon = iconComponentGenerator(IconEntityMap.FilterList);
export const ReorderIcon = iconComponentGenerator(IconEntityMap.Reorder);
export const ExpandCircleDownIcon = iconComponentGenerator(
    IconEntityMap.ExpandCircleDown,
);
export const FilterListOffIcon = iconComponentGenerator(
    IconEntityMap.FilterListOff,
);
export const SortUpIcon = iconComponentGenerator(IconEntityMap.SortUp);
export const SortDownIcon = iconComponentGenerator(IconEntityMap.SortDown);
export const UnSortedIcon = iconComponentGenerator(IconEntityMap.UnSorted);

// Secondary area icons
export const SendFeedbackIcon = iconComponentGenerator(
    IconEntityMap.SendFeedback,
);
export const CancelIcon = iconComponentGenerator(IconEntityMap.Cancel);
export const DownloadIcon = iconComponentGenerator(IconEntityMap.Download);
export const UploadIcon = iconComponentGenerator(IconEntityMap.Upload);
export const MoreOptionsIcon = iconComponentGenerator(
    IconEntityMap.MoreOptions,
);
export const AddIcon = iconComponentGenerator(IconEntityMap.Add);
export const ForwardIcon = iconComponentGenerator(IconEntityMap.Forward);
export const SettingsIcon = iconComponentGenerator(IconEntityMap.Settings);
export const ErrorIcon = iconComponentGenerator(IconEntityMap.Error);
export const InfoIcon = iconComponentGenerator(IconEntityMap.Info);
export const CheckIcon = iconComponentGenerator(IconEntityMap.Success);
export const HelpIcon = iconComponentGenerator(IconEntityMap.Help);
export const EditIcon = iconComponentGenerator(IconEntityMap.Edit);
export const CloseIcon = iconComponentGenerator(IconEntityMap.Close);
export const SearchIcon = iconComponentGenerator(IconEntityMap.Search);
export const NorthEastIcon = iconComponentGenerator(IconEntityMap.NorthEast);
export const ChevronLeftIcon = iconComponentGenerator(
    IconEntityMap.ChevronLeft,
);
export const ChevronRightIcon = iconComponentGenerator(
    IconEntityMap.ChevronRight,
);
export const ChevronUpIcon = iconComponentGenerator(IconEntityMap.ChevronUp);
export const ChevronDownIcon = iconComponentGenerator(
    IconEntityMap.ChevronDown,
);
export const ChevronStartIcon = iconComponentGenerator(
    IconEntityMap.ChevronStart,
);
export const CompactViewIcon = iconComponentGenerator(
    IconEntityMap.CompactView,
);
export const RoomyViewIcon = iconComponentGenerator(IconEntityMap.RoomyView);
export const CopyToClipboardIcon = iconComponentGenerator(
    IconEntityMap.CopyToClipboard,
);
export const DeleteForeverIcon = iconComponentGenerator(
    IconEntityMap.DeleteForever,
);
export const CalendarIcon = iconComponentGenerator(IconEntityMap.Calendar);

// TabIcons
export const InfoTabIcon = InfoIcon;
export const ProjectComponentsTabIcon = ProjectComponentsIcon;
export const ProjectsTabIcon = ProjectsIcon;
export const SecurityNotificationTabIcon = SecurityNotificationIcon;
export const HelpTabIcon = HelpIcon;
export const SummarizeTabIcon = iconComponentGenerator(IconEntityMap.Summarize);
export const BallotTabIcon = iconComponentGenerator(IconEntityMap.Ballot);
export const CommentTabIcon = iconComponentGenerator(IconEntityMap.Comment);
